<template>
  <div class="row">

    <div class="row__group row__group--main">
      <div class="row__label">
        {{ $t('message.holidays.overview.from') }}
      </div>
      <div class="row__info">
        <div class="row__info__base">
          <b>{{ content.from }}</b>
        </div>
        <a href="javascript:void(0);" class="row__item__trigger" @click.prevent.stop="handleClick" v-if="!isDesktop">
          <BaseIcon :type="icon" />
        </a>
      </div>
    </div>

    <div class="row__group row__group--secondary" v-show="showInfos">
      <div class="row__item">
        <div class="row__label">
          {{ $t('message.holidays.overview.till') }}
        </div>
        <div class="row__info">
          {{ content.till }}
        </div>
      </div>
      <div class="row__item">
        <div class="row__label">
          {{ $t('message.holidays.overview.days') }}
        </div>
        <div class="row__info">
          <b>{{ content.days }}</b>
        </div>
      </div>
      <div class="row__item">
        <div class="row__label">
          {{ $t('message.holidays.overview.status') }}
        </div>
        <div class="row__info">
          <span :class="`is-${content.status}`">{{ content.status }}</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@base/BaseIcon'
import ElementRow from '@elements/ElementRow'
export default {
  name: 'HolidaysTableBody',
  extends: ElementRow,
  components: {
    BaseIcon
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      showInfos: true
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    icon () {
      return !this.showInfos ? 'chevron-down' : 'chevron-up'
    },
    isDesktop () {
      return this.device === 'desktop'
    }
  },
  watch: {
    isDesktop () {
      this.showInfos = true
    }
  },
  methods: {
    handleClick () {
      this.showInfos = !this.showInfos
    }
  }
}
</script>

<style lang="scss">
  .row__info{
    span{
      text-transform: capitalize;
      &.is-pending{
        color: colors("warning");
      }
      &.is-approved{
        color: colors("success");
      }
      &.is-comment{
        color: tint(colors("primary"), 30%);
      }
    }
  }

</style>
