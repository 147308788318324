<template>
  <div class="row">

    <!-- <div class="row__group row__group--check">---</div>

    <div class="row__group row__group--main">
      <div class="row__label">---</div>
      <div class="row__info">---</div>
    </div>

    <div class="row__group row__group--secondary" v-show="showInfos">
      <div class="row__item">
        <div class="row__label">---</div>
        <div class="row__info">---</div>
      </div>
    </div> -->

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'elementRow',
  components: {},
  props: {
    content: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      showInfos: false,
      expand: false
      // active: false
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    icon () {
      return !this.showInfos ? 'chevron-down' : 'chevron-up'
    },
    isDesktop () {
      return this.device === 'desktop'
    }
  },
  watch: {
    device () {
      this.updateShowInfos()
    }
  },
  created () {
    this.updateShowInfos()
  },
  methods: {
    updateShowInfos () {
      this.showInfos = this.device === 'desktop'
    },
    handleClick () {
      this.showInfos = !this.showInfos
    }
  }
}
</script>

<style lang="scss">
  .row{
    @media print {
      border-top: 1px solid colors("border");
      font-size: 10pt;
      page-break-inside: avoid;
    }
    @include media("large"){
      &:nth-child(even){
        background-color: #f1f1f1,
      }
    }

    margin: 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;

    &--header{
      @media print {
        font-size: 1.3rem;
        a,
        .icon-text,
        .icon::before{
          color: white !important;
        }
      }
      background-color: colors("topbar");
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      font-size: 1.4rem;

      a,
      .icon-text,
      .icon::before{
        color: white;
        font-weight: font-weights('medium');
      }

      .icon{
        font-size: 1.4rem;
      }

      .badge{
        .contacts-list-view &{
          display: none;
        }
      }

      .row__group{
        @include media("large"){
          &:first-child{
            border-left-color: colors("topbar");
          }
          &:last-child{
            border-right: 1px solid colors("topbar");
            .row__item{
              &:last-child{
                border-right: 0;
              }
            }
          }
        }
      }
      .row__label{
        @include media("small-medium"){
          font-size: 1.6rem;
        }
        display: block;
      }
    }

    // Group

    &__group{
      @media print {
        padding: layout("spacing")*.6 layout("spacing")*.8;
        flex: 6;
        .user-detail--table &{
          flex: 2;
        }
      }

      @include media("small-medium"){
        padding: layout("spacing")*.5 layout("spacing")*.6;
        border-left: 1px solid colors("border");
        border-bottom: 1px solid colors("border");
        background: #F1F1F1;
      }

      @include media("large"){
        padding: layout("spacing")*.6 layout("spacing")*.8;
        flex: 6;
        .user-detail--table &{
          flex: 2;
        }
      }

      &:first-child{
        border-left: 1px solid colors("border");
      }

      flex: 1 1 auto;
      border-right: 1px solid colors("border");

      &--check{
        @media print {
          display: none !important;
        }
        flex: 0 0 auto;
      }

      &--main{
        @media print {
          flex: 2;
        }
        @include media("small-medium"){
          border-left: 0;
          flex: 1;
          .icon-text{
            margin-bottom: .5rem;
          }
        }
        @include media("large"){
          flex: 2;
        }
        hyphens: auto;

        .row__info{
          display: flex;
          align-items: flex-start;
        }
        a{
          font-weight: font-weights("bold");
        }
      }

      &--secondary{
        @media print {
          .row__item:first-child {
            border-left: 0;
            flex: 6;
            .user-detail--table &{
              flex: 2;
            }
          }
        }
        @include media("small-medium"){
          position: relative;
          flex-direction: column;
          width: 100%;

          > div{
            background: #e7e7e7;
            border-bottom: 1px solid #ccc;
          }

          &:before{
            content:"";
            display: block;
            width: 100%;
            height: .5rem;
            position: absolute;
            top: 0;
            left: 0;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
            background-repeat: repeat-x;
          }

        }
        @include media("large"){
          .row__item:first-child {
            border-left: 0;
            flex: 6;
            .user-detail--table &{
              flex: 2;
            }
          }
        }
        display: flex;
        padding: 0;
        border: 0;
      }
    }

    // Info

    &__info{
      &__expand{
        .icon{
          margin-left: 1rem;
          font-size: 1rem;
        }
      }

      // Avatar

      &__avatar{
        @media print {
          display: none !important;
        }
        @include media("small-medium"){
          margin-right: layout("spacing")/2;
        }
        @include media("large"){
          margin-right: layout("spacing");
        }
        @include size(4rem);
        background-image: url("./_images/default-avatar.png");
        background-size: cover;
        flex: 0 0 auto;
      }

      // Base

      &__base{
        margin-right: layout("spacing")*.8;
        flex: 1 1 auto;
      }

      // Function

      &__function{
        font-size: 1.4rem;
        font-style: italic;
      }
    }

    // Item

    &__item{
      @media print{
        padding: layout("spacing")*.6 layout("spacing")*.8;
        flex: 6;
        .user-detail--table &{
          flex: 2;
        }
      }

      @include media("small-medium"){
        padding: layout("spacing")*.5 layout("spacing")*.6;
        border-left: 1px solid colors("border");
        border-bottom: 1px solid colors("border");
        background: #F1F1F1;
      }

      @include media("large"){
        padding: layout("spacing")*.6 layout("spacing")*.8;
        flex: 6;
        .user-detail--table &{
          flex: 2;
        }
      }

      flex: 1 1 auto;
      // flex-basis: 20px;
      overflow: hidden;
      border-right: 1px solid colors("border");
      hyphens: auto;
      text-overflow: ellipsis;
      white-space: normal;
      overflow-wrap: break-word;

      .button {
        white-space: normal;
      }

    }

    // Label

    &__label{
      @media print{
        display: none;
      }
      @include media("small-medium"){
        font-size: 1.4rem;
        color: #919191;
      }
      @include media("large"){
        display: none;
      }

      .icon{
        font-size: 1.4rem;
      }
    }

    .badge{
      margin: 0;

      &__color {
        margin: 0;
      }

      .contacts-list-view &{
        display: none;
      }
    }
  }
</style>
