<template>
  <div class="table">
    <slot name="header"/>
    <slot name="body"/>
  </div>
</template>

<script>
export default {
  name: 'ElementTable'
}
</script>

<style lang="scss">
  .table{
    @media print {
      border-bottom: 1px solid colors("border");
      border-top: 1px solid colors("border");
      page-break-inside: avoid;
      display: table
    }
    @include media("small-medium"){
      border-top: 1px solid colors("border");
    }
    @include media("large"){
      border-bottom: 1px solid colors("border");
    }

    width: 100%;

    // Header

    &__header{
      background-color: colors("topbar");
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      font-size: 1.4rem;

      .icon-text,
      .icon::before{
        color: white;
      }

      .icon{
        font-size: 1.4rem;
      }

      .badge{
        .contacts-list-view &{
          display: none;
        }
      }
    }

    .checkbox__square{
      @include media("small-medium"){
        width: 2.4rem;
        height: 2.4rem;
      }
    }

  }
</style>
