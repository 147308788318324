<template>
  <div class="card-user">
    <div class="card-user__inner">
      <div v-if="content.avatar" :style="{'background-image': 'url(' + content.avatar + ')'}" class="preview card-user__preview preview--avatar preview--large"></div>
      <ElementPreview v-else class="card-user__preview" type="avatar" />
      <div class="card-user__description">
        <div class="card-user__main">
          <div>
            <span class="user-title" v-if="content.title">{{ content.title }} </span>
            <span class="user-firstname">{{ content.firstname }} </span>
            <span class="user-lastname">{{ content.lastname }} </span>
            <span class="user-middlename">{{ content.middlename }} </span>
          </div>
          <div v-if="content.function">
            <span class="user-function">{{ content.function }}</span>
              {{ $t(`message.common.at`) }}
            <span class="user-company">{{ content.company }}</span>
          </div>
        </div>
        <BaseButton
          v-if="content.email"
          icon="mail"
          icon-position="right"
          :url="`mailto:${ content.email }`">
            {{ $t(`message.common.buttons.sendEmail`) }}
        </BaseButton>
        <BaseButton
          v-if="showButton"
          icon="events"
          icon-position="right"
          @click.native="openModal">
            {{ $t(`message.common.buttons.${displayType}Request`) }}
        </BaseButton>

      </div>
    </div>

  </div>
</template>

<script>
import ElementPreview from '@elements/ElementPreview'
import BaseButton from '@base/BaseButton'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'CardUser',
  props: {
    displayType: {
      type: String,
      required: false,
      default: 'user'
    },
    content: {
      type: Object,
      required: true
    }
  },
  components: {
    ElementPreview,
    BaseButton
  },
  data () {
    return {
      // holidayModal: false
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice'
    }),
    isDesktop () {
      return this.device === 'desktop'
    },
    showButton () {
      return this.displayType !== 'user'
    }
  },
  methods: {
    ...mapActions({
      updateName: 'modal/updateName'
    }),
    openModal () {
      this.updateName(this.displayType)
    }
  }
}
</script>

<style lang="scss">
.card-user {
  @media print {
    margin-bottom: layout("spacing")*1.5;
    padding: layout("spacing");
    background-color: transparent;
    border-left: 6px solid shade(colors("primary"), 15%);
  }
  @include media("small-medium") {
    padding: layout("spacing")/2;
    border-top: 6px solid shade(colors("primary"), 15%);
  }
  @include media("large") {
    margin-bottom: layout("spacing")*1.5;
    padding: layout("spacing");
    border-left: 6px solid shade(colors("primary"), 15%);
  }

  width: 100%;
  border-radius: layout("border-radius");
  background-color: tint(colors("primary"), 20%);

  &__main{
    @include media("small-medium") {
      margin-bottom: 1rem;
    }
  }

  // Inner

  &__inner{
    color: white;
    display: flex;
    justify-content: flex-start;
  }

  // Preview

  &__preview{
    @media print {
      display: none !important;
    }
    flex: 0 0 auto;
  }

  // Description

  &__description{
    @media print {
      color: black;
    }
    @include media("small-medium") {
      flex-direction: column;
      align-items: flex-start;
    }
    @include media("large") {
      align-items: center;
    }

    flex: 1 1 auto;
    padding-left: layout("spacing");
    padding-bottom: layout("spacing")/2;
    font-size: 1.6rem;

    display: flex;
    justify-content: space-between;

    .user-title{
      font-size: 2.2rem;
    }
    .user-firstname{
      font-size: 2.2rem;
      font-weight: font-weights("medium")
    }
    .user-lastname{
      font-size: 2.2rem;
      font-weight: font-weights("medium");
      text-transform: uppercase;
    }
    .user-middlename{
      font-size: 1.4rem;
    }
    .user-function{
      font-size: 1.6rem;
      font-weight: font-weights("medium");
    }

    .button{
      @media print {
        display: none !important;
      }
    }

  }

  // Title

  &__title{
    a{
      margin-right: 1rem;
    }
  }

  .button{
    @media print {
      display: none !important;
    }
    &:hover{
      color: white;
    }
  }
}
</style>
