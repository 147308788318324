<template>
  <div class="holidays-entitlement">
    <!-- Header -->
    <HubHeader
      class="holidays-entitlement__title"
      :titleTag="'h3'"
      :title="$t('message.holidays.entitlement.title')">
      <div class="holidays-entitlement__select">
        <select v-model="selected">
          <option disabled value="">Nothing Selected</option>
          <option v-for="(item, index) in entitlement" :key="`year-${index}`" :value="item.year" :selected="{'selected' : index === 0}">{{ item.year }}</option>
        </select>
      </div>
    </HubHeader>
    <!-- Content -->
    <div class="holidays-entitlement__content">
      <HolidaysEntitlementItem v-for="(item, index) in entitlement" :selected="selected" :content="item" :key="`entitlement-${index}`" />
    </div>
  </div>
</template>

<script>
import HubHeader from '@blocks/hubs/HubHeader'
import HolidaysEntitlementItem from './HolidaysEntitlementItem'
export default {
  name: 'HolidaysEntitlement',
  props: {
    entitlement: {
      type: Array,
      required: true
    }
  },
  components: {
    HubHeader,
    HolidaysEntitlementItem
  },
  data () {
    return {
      selected: ''
    }
  }
}
</script>

<style lang="scss">
  .holidays-entitlement{
    &__select{
      @include custom-select;
    }
  }
</style>
