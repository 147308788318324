import { mapActions } from 'vuex'
export default {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.updateRoutes(to.matched)
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.updateRoutes(to.matched)
    next()
  },
  methods: {
    ...mapActions({
      'updateRoutes': 'breadcrumb/updateRoutes'
    })
  }
}
