<template>
  <div class="holidays-view">
    <HubHolidays
      name="holidays"
      :entitlement="entitlement"
      :overview="overview"
      :user="user"
      v-if="loaded" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HubHolidays from '@blocks/hubs/HubHolidays'
import updateBreadcrumb from '@mixins/updateBreadcrumb'

export default {
  name: 'HolidaysView',
  mixins: [ updateBreadcrumb ],
  components: {
    HubHolidays
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      entitlement: 'holidays/getEntitlement',
      overview: 'holidays/getOverview',
      user: 'holidays/getUser'
    })
  },
  created () {
    this.fetchGetPage({ service: 'holidays' }).then(() => {
      this.loaded = true
    })
  },
  methods: {
    ...mapActions({
      'fetchGetPage': 'page/fetchGetPage'
    })
  }
}
</script>

<style lang="scss">
  .holidays-details{
    &__select{
      @include icon(icons("chevron-down"));
      position: relative;
      max-width: 22rem;
      &:before{
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        color: colors('primary');
        display: block;
        width: 2rem;
        height: 2rem;
      }
      select{
        margin-bottom: 0;
        padding-right: 5rem;
        color: #404040;
        background-color: colors('foreground');
        border-color: colors('border');
      }
    }
  }
</style>
