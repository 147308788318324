<template>
  <div class="holidays-overview">
    <HubHeader
      class="holidays-details__title"
      :titleTag="'h3'"
      :title="$t('message.holidays.overview.title')" />
    <ElementTable v-if="overview">
      <template slot="header">
        <HolidaysTableHeader />
      </template>
      <template slot="body">
        <HolidaysTableBody v-for="(item, index) in overview" :content="item" :key="`overview-${index}`" />
      </template>
    </ElementTable>
    <BaseButton
      icon="more"
      icon-position="right"
      color="primary"
      v-if="offset < total"
      @click.native="handleClick">
        {{ $t(`message.common.buttons.viewMore`) }}
    </BaseButton>
  </div>
</template>

<script>
import ElementTable from '@elements/ElementTable'
import HolidaysTableHeader from './HolidaysTableHeader'
import HolidaysTableBody from './HolidaysTableBody'
import BaseButton from '@base/BaseButton'
import HubHeader from '@blocks/hubs/HubHeader'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'HolidaysOverview',
  props: {
    overview: {
      type: Array,
      required: true
    }
  },
  components: {
    HubHeader,
    ElementTable,
    BaseButton,
    HolidaysTableHeader,
    HolidaysTableBody
  },
  computed: {
    ...mapGetters({
      offset: 'holidays/getOffsetOverview',
      total: 'holidays/getTotalOverview'
    })
  },
  methods: {
    ...mapActions({
      fetchGetAddHolidaysOverview: 'page/fetchGetAddHolidaysOverview'
    }),
    handleClick () {
      this.fetchGetAddHolidaysOverview()
    }
  }
}
</script>

<style lang="scss">
.holidays-overview{
  > * {
    margin-bottom: layout("spacing");
  }

  .button{
    @media print {
      display: none !important;
    }
  }
}
</style>
