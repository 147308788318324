<template>
  <ContainerHub class="hub-holidays">

    <!-- HEADER -->
    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="title"
        :email="email"
        :description="description" />
    </template>

    <!-- BODY -->
    <template slot="body">
      <CardUser
        :display-type="'holiday'"
        :content="user"
        v-if="user" />

      <HolidaysEntitlement :entitlement="entitlement" />
      <HolidaysOverview :overview="overview" />
    </template>
  </ContainerHub>
</template>

<script>
import { mapGetters } from 'vuex'

import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'
import CardUser from '@cards/user/CardUser'
import HolidaysEntitlement from '@blocks/holidays/HolidaysEntitlement'
import HolidaysOverview from '@blocks/holidays/HolidaysOverview'

export default {
  name: 'HubHolidays',
  props: {
    name: {
      type: String,
      required: true
    },
    entitlement: {
      type: Array,
      required: true
    },
    overview: {
      type: Array,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail'
    }),
    displayType () {
      return 'large'
    }
  },
  components: {
    HubHeader,
    ContainerHub,
    CardUser,
    HolidaysEntitlement,
    HolidaysOverview
  }
}
</script>

<style lang="scss">
  .hub-holidays{
    .container-hub__body{

    }
  }
</style>
