<template>

  <div class="holidays-entitlement-item" v-if="selected === content.year" :id="content.year">

    <div class="holidays-entitlement-item__group" v-for="key in Object.keys(content)" :key="key">
      <div class="holidays-entitlement-item__label">{{ $t(`message.holidays.entitlement.${key}`) }}</div>
      <div class="holidays-entitlement-item__data">{{ content[key] }}</div>
    </div>

  </div>

</template>

<script>
export default {
  name: 'HolidaysEntitlementItem',
  props: {
    content: {
      type: Object,
      required: true
    },
    selected: {
      type: String,
      required: false,
      default: ' '
    }
  }
}
</script>

<style lang="scss">
  .holidays-entitlement-item{
    font-size: 1.4rem;

    // Group

    &__group{
      @include media("small-medium"){
        flex-direction: column;
      }
      display: flex;
      margin-bottom: 1rem;
      padding: layout("spacing")/2 layout("spacing");
      background-color: tint(black, 96%);
      border-radius: 4px;

      &:nth-last-child(-n+2) {
        background-color: tint(black, 85%);
      }

      &:nth-last-child(2) {
        margin-top: 2.5rem;
      }

      > div {
        @include media("large"){
          flex: 2;
        }
      }
    }

    // Label

    &__label{
      margin-right: 2rem;
      flex: 1 0 auto;
      color: tint(black, 50%);
    }

    &__data{
      font-weight: font-weights('bold')
    }
  }
</style>
